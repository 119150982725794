import React from 'react';
import { Global } from '../../core/styles';
import '../../core/fonts.css';
import Footer from "../theme/Footer";
import {SidebarState} from "../theme/Header";

export default function Layout({children}) {
	return (
		<SidebarState>
			<Global />
			{children}
			<Footer />
		</SidebarState>
	);
}