import React from 'react';
import styled from 'styled-components';
// import Github from 'mdi-material-ui/GithubCircle';
import LinkedIn from 'mdi-material-ui/LinkedinBox';
import LogoText from "../common/LogoText";
import {theme} from "../../core/theme";

import {socialLinks} from '../../../data/config';
import {fonts} from "../../core/styles";
import {colours} from "../../core/colours";


export default function Footer() {
	return (
		<Wrapper>
			<FooterWrapper>
				<Grid>
					<Details>
            <p><Logo>BuiltByBrayne</Logo> is an app development service provided by <ParentCompany><a href="https://abetterworld.company" target="_blank" rel="noopener noreferrer">A Better World Ltd</a></ParentCompany></p>
						<p><Company>Company No. <span>08887481</span></Company> <Copyright>© {new Date().getFullYear()} All rights are reserved</Copyright></p>
					</Details>
					<Attributions>
						Vector images adapted from <a href="http://rawpixel.com" target="_blank" rel="noopener noreferrer">rawpixel.com</a>
					</Attributions>
					<Links>
						<a href={socialLinks.linkedin} target="_blank" rel="noopener noreferrer"><LinkedIn /></a>
						{/* <a href={socialLinks.github} target="_blank" rel="noopener noreferrer"><Github /></a> */}
					</Links>
				</Grid>
			</FooterWrapper>
		</Wrapper>
	);
}



const Wrapper = styled.div`
	position: relative;		
	z-index: 10;	
`;
const FooterWrapper = styled.div`
	background: ${theme.rgba.tertiary.lighter()};
`;

const Grid = styled.div`
	display: grid;
	align-items: flex-end;
	grid-template-columns: 1fr 1fr 1fr;
	justify-content: space-between;
	padding: 1rem 2rem;
	
	@media (max-width: 680px) {
		display: flex;
		flex-direction: column-reverse;
		text-align: center;
		align-items: center;
	}
`;

const Links = styled.div`
	justify-self: end;
	display: flex;
	align-items: center;

	a {
		color: ${theme.hex.tertiary.darker};
		margin: 0 0.5rem;
		
		&:first-child,
		&:last-child {
			margin: 0;
		}
		
		svg {
		width: 2rem;
		height: 2rem;
	}
`;

const Details = styled.div`
	color: ${theme.hex.tertiary.darkest};
	justify-self: start;
	
	p {
		margin: 0;
		font-size: 0.8em;
	}
	
	
	@media (max-width: 680px) {
		margin-bottom: 0rem;
	}
`;

const Attributions = styled.div`
	justify-self: center;
	font-size: 0.8rem;
	opacity: 0.7;
	color: ${theme.hex.tertiary.darkest};
	a {
		color: ${theme.hex.tertiary.darker}; 
	}
	@media (max-width: 680px) {
		padding: 1rem 0;
	}
`;

const Logo = styled(LogoText)`
	font-size: 0.9rem;
	`;

const Company = styled.span`
	font-style: italic;
	span {
		${fonts.lato}
	}	
	
`;
const ParentCompany = styled.span`
	font-family: 'Playfair Display', serif;
	font-weight: bold;	
	white-space: nowrap;
	font-size: 0.9rem;
	a {
	  color: ${theme.hex.tertiary.darker};
	  &:hover, &:active {
	    color: ${theme.hex.tertiary.dark};
	  }
	  &:visited: {
	    color: ${theme.hex.tertiary.darkest};
	  }
	}
`;

const Copyright = styled.span`
	@media (max-width: 680px) {
		display: block;
	}
`;