import getContrastText from '@tgrx/getcontrasttext';


export const theme = {
	hex: {
		primary: {
			main: '#0842D4',	// Main Primary color */
			lighter: '#678AE0',
			light: '#2B5FDF',
			dark: '#062D90',
			darker: '#031D5D',
			darkest: '#010715',
		},
		secondary: {
			main: '#FFD500',	// Main Secondary color (1) */
			lightest: '#fff4db',
			lighter: '#FFE565',
			light: '#FFD919',
			dark: '#D7B300',
			darker: '#804a00',
			darkest: '#402500'
		},
		tertiary: {
			main: '#FF7600',	// Main Secondary color (2) */
			lightest: '#ffddab',
			lighter: '#FFAC65',
			light: '#FF8319',
			dark: '#D76300',
			darker: '#8A4000',
			darkest: '#361800'
		}
	},
	rgba: {
		primary: {
			main: (op=1) => `rgba(  8, 66,212,${op})`,	// Main Primary color */
			lighter: (op=1) =>  `rgba(103,138,224, ${op})`,
			light: (op=1) =>  `rgba( 43, 95,223, ${op})`,
			dark: (op=1) =>  `rgba(  6, 45,144, ${op})`,
			darker: (op=1) =>  `rgba(  3, 29, 93, ${op})`,
			darkest: (op=1) =>  `rgba(  1, 7, 21, ${op})`
		},
		secondary: {
			main: (op=1) =>  `rgba(255,213,  0, ${op})`,	// Main Secondary color (1) */
			lightest: (op=1) =>  `rgba(255,248,231, ${op})`,
			lighter: (op=1) =>  `rgba(255,229,101, ${op})`,
			light: (op=1) =>  `rgba(255,217, 25, ${op})`,
			dark: (op=1) =>  `rgba(215,179,  0, ${op})`,
			darker: (op=1) =>  `rgba(138,115,  0, ${op})`,
			darkest: (op=1) =>  `rgba(64,37,  0, ${op})`
		},
		tertiary: {
			main: (op=1) =>  `rgba(255,118,  0, ${op})`,	// Main Secondary color (2) */
			lightest: (op=1) =>  `rgba(255,221,171, ${op})`,
			lighter: (op=1) =>  `rgba(255,172,101, ${op})`,
			light: (op=1) =>  `rgba(255,131, 25, ${op})`,
			dark: (op=1) =>  `rgba(215, 99,  0, ${op})`,
			darker: (op=1) =>  `rgba(138, 64,  0, ${op})`,
			darkest: (op=1) =>  `rgba(54, 24,  0, ${op})`
		}
	}
};

Object.values(theme.hex).forEach(c => {
	c.contrastText = getContrastText.default({
		background: c.main,
		light: {text: {primary: c.light}},
		dark: {text: {primary: c.dark}},
		contrastThreshold: 3
	});
});

Object.values(theme.rgba).forEach(c => {
	c.contrastText = getContrastText.default({
		background: c.main(),
		light: {text: {primary: c.light()}},
		dark: {text: {primary: c.dark()}},
		contrastThreshold: 3
	});
});
