import {useCallback, useEffect, useState} from 'react';
import Smoothscroll from 'smoothscroll-polyfill';
import isFunction from 'lodash.isfunction';

export function useSmoothScroll(id, offset) {
	useEffect(() => {
		Smoothscroll.polyfill();
	});

	return () => {
		let _offset = () => 0;
		if (typeof offset !== 'undefined') {
			if (!!(offset && offset.constructor && offset.apply)) {
				_offset = offset;
			} else {
				_offset = () => parseInt(offset);
			}
		}
		const $anchor = document.getElementById(id);
		const offsetTop = $anchor.getBoundingClientRect().top + window.pageYOffset;
		window.scroll({
			top: offsetTop - _offset(),
			behavior: 'smooth'
		});
	};
}


export function useToggleState(initialValue, onToggle) {
	const [state, setState] = useState(initialValue);
	const toggle = useCallback(() => {
		setState(!state);
		if (isFunction(onToggle)) {
			onToggle(!state);
		}
	}, [state, setState]);
	return [state, toggle];
}
